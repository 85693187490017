







import './styles/ProjectCardInfoBlock.scss';
import './styles/ProjectCardInfoBlockAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ProjectCard',

    components: {},
})
export default class ProjectCardInfoBlock extends Vue {
    @Prop({ default: '' }) title!: string;
}
